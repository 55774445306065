<template >
  <div class="justify-content-between align-items-center content-sidebar px-1 py-1">
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group 
            :label="$t('WorkcenterList.Name')" 
            label-for="name"
            :invalid-feedback="flagNameEqual ? $t('WorkcenterList.WorkcenterNameAlreadyExist') : $t('RequiredField')"
            id="form-workcenter-name"
          >
            <b-form-input 
              id="name" 
              v-model="data.name" 
              :placeholder="($t('WorkcenterList.Placeholder'))" 
              :state="stateName"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <VSelectChips
            key="select-chips-workcenter-edit-resource"
            :label="$t('WorkcenterList.Resources')"
            :label-for="'resources'"
            :placeholder="($t('WorkcenterList.Select'))"
            :valueSelectMultiple="data.resources"
            :options="resourcesList"
            :stateField="stateResource"
            @updateData="(value) => data.resources = value"
          >
            <span class='is-invalid-span' v-if="stateResource == false">{{$t('RequiredField')}}</span>
          </VSelectChips>
        </b-col>

        <b-col md="12">
          <VSelectChips
            key="select-chips-workcenter-edit-user"
            :label="$t('WorkcenterList.Users')"
            :label-for="'users'"
            :placeholder="($t('WorkcenterList.Select'))"
            :valueSelectMultiple="data.users"
            @updateData="(value) => data.users = value"
            :options="usersList"
            :stateField="stateUser"
          >
            <span class='is-invalid-span' v-if="stateUser == false">{{$t('RequiredField')}}</span>
          </VSelectChips>
          
        </b-col>

        <b-col md="12" class="col-buttons">
          <b-form-group>
            <b-button v-if="!sendingForm" variant="primary" class="form-control btn-save-workcenter" @click="updateWorkcenter(data.id)">
              {{ $t('WorkcenterList.Save') }}
            </b-button>

            <b-button v-if="sendingForm" variant="primary" disabled class="form-control btn-save-workcenter">
              <b-spinner small />
              Loading ...
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { TheMask } from 'vue-the-mask'
import vSelect from 'vue-select'

import {
  BAvatar, BListGroup, BListGroupItem, BCardText, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BSpinner, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import VSelectChips from '@/@core/components/v-select/vSelectChips.vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    TheMask,
    flatPickr,
    ToastificationContent,
    vSelect,
    VSelectChips
},
  directives: {
    Ripple,
  },
  props: {
    currentWorkcenterId: {
      type: String,
    },
    nameWorkcenters: {
      type: Array
    }
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      name: '',
      sendingForm: false,
      data: [],
      resourcesList: [],
      usersList: [],
      stateName: null,
      stateResource: null,
      stateUser: null,
      flagNameEqual: false,
    }
  },
  created() {
    this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/resource?site_guid=${this.currentSite.guid}`)
      .then(response =>
        response.data.forEach(el => this.resourcesList.push({ label: el.name, value: el.id})))
      .catch(error => this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger'))
    this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/users?site_guid=${this.currentSite.guid}`)
      .then(response =>
        response.data.response.forEach(el => this.usersList.push({ label: `${el.first_name} ${el.last_name}`, value: el.guid})))
      .catch(error => this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger'))

    this.getCurrentWorkcenterId(this.currentWorkcenterId);

    this.watchClickOutsideModal();
  },
  methods: {
    validaCampos(){
      let nameEqual = this.nameWorkcenters.find(item => item == this.data.name);
      this.flagNameEqual = (nameEqual && this.data.name) && this.data.name != this.name ? true : false;

      this.stateName = this.data.name && this.flagNameEqual == false ? null : false;
      this.stateResource = this.data.resources.length > 0  ? null : false;
      this.stateUser = this.data.users.length > 0  ? null : false;
    },
    getCurrentWorkcenterId(id) {
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/workcenter/${id}`)
        .then((response) => { 
          this.data = response.data; 
          this.name = this.data.name;
        })
    },
    updateWorkcenter(id) {
      this.validaCampos();
      if(this.stateName != false && this.stateResource != false && this.stateUser != false){
        let resourcesIds = []
        this.data.resources.forEach(el => resourcesIds.push(el.value))
        let usersIds = []
        this.data.users.forEach(el => usersIds.push(el.value))

        this.sendingForm = true
        this.$http.put(`/api/${this.$router.currentRoute.params.prefix}/workcenter/${id}?site_guid=${this.currentSite.guid}`, { name: this.data.name, resources: resourcesIds, users: usersIds })
          .then(res => {
            this.sendingForm = false
            this.$emit('refetch-data')
            this.$emit('show-message')
            this.showToast(this.$t('WorkcenterList.Success'), 'SmileIcon', this.$t('WorkcenterList.CreatedText'), 'success')
          })
          .catch((error) => {
            this.sendingForm = false
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
          })
      }
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
    watchClickOutsideModal() {
      window.addEventListener('click', (element)=> {
        if(element.target.id === 'modal-edit-workcenter' && document.getElementsByClassName('vs__dropdown-menu').length == 0)
          this.$bvModal.hide('modal-edit-workcenter')
      });
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#modal-edit-workcenter {
  .form-control.is-invalid{
      background-image: none !important;
  }

  .vue-close-select{
    svg path {
      fill: #fff !important;
    }
  }
}

.col-buttons{

  display: flex;
  justify-content: end;
  .btn-save-workcenter {
    width: auto;
    align-items: center;
    display: flex;
    background: #974900 !important;
    border-radius: 5px !important;
    border-color: #974900 !important;
    font-weight: 600;
    font-size: 17px;
    span{
      margin-right: 5px;
    }
  }

  &:focus {
    background: #974900 !important;
    border-color: #974900 !important;
  }
}

</style>