<template>
    <div class="row">
        <!-- search input -->
        <div :class="`custom-search col-${sizeSearch}`" v-if="searchFilter">
            <div class="d-flex align-items-center">
                <b-form-input
                    v-model="filterTerm"
                    :placeholder="$t('SearchPlaceholderDefault')"
                    type="text"
                    class="input-search"
                />
            </div>
        </div>
        <div :class="`col-${12 - sizeSearch}`">
          <slot name="col-right" />
        </div>
        <div class="container-fluid filter-options">
            <slot name="filter"/>
        </div>
        <!-- Table -->
        <b-table
            id="table-default"
            :sticky-header="stickyHeader"
            :no-border-collapse="noCollapse"
            :items="rowsTable"
            :fields="fields"
            :per-page="pageLength"
            :current-page="currentPage"
            :searchFilter="searchFilter"
            :striped="striped"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            class="table-responsive"
        >
            <!-- Slot: Table Column -->
            <template #head()="scope">
                <div class="text-nowrap">
                    {{ $t(scope.label) }}
                </div>
            </template>

            <!-- Slot: Table Row -->

            <template
                v-for="slot in Object.keys($scopedSlots)"
                v-slot:[slot]="props"
            >
                <slot v-bind="props" :name="slot" />
            </template>
        </b-table>

        <div class="not-found" v-if="rowsTable.length == 0">
            <span><SearchIcon /><h3>{{ $t('NoResultsFound') }}</h3></span>
        </div>

        <div class="col-12 d-flex justify-content-between col-footer-table pagination-table">
            <div class="d-flex align-items-center mb-0 mt-1 por-pagina">
                <span class="text-nowrap">
                    {{ $t("message.pagelength") }}
                </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['10', '20', '30']"
                    class="mx-1"
                />
            </div>

            <b-pagination
                aria-controls="table-default"
                v-model="currentPage"
                :total-rows="rowsTable.length"
                :per-page="pageLength"
                class="mt-1 mb-0"
                :value="1"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                @input="$emit('alteraPaginaListagem', currentPage)"
            >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
            </b-pagination>
        </div>
    </div>
</template>

<script>
import SearchIcon from '@/assets/images/pages/Search.svg';
import {
    BPagination,
    BTable,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BFormInput,
    BFormGroup,
} from "bootstrap-vue";

export default {
    components: {
        BPagination,
        BTable,
        BFormSelect,
        BDropdownItem,
        BDropdown,
        BFormInput,
        BFormGroup,
        SearchIcon
    },
    props: {
        rows: {
            type: Array,
            default: null,
        },
        fields: {
            type: Array,
            default: null,
        },
        noCollapse: {
            type: Boolean,
            default: true,
        },
        stickyHeader: {
            type: String,
            default: true,
        },
        searchFilter: {
            type: Boolean,
            default: true,
        },
        striped: {
            type: Boolean,
            default: false,
        },
        sortDesc: {
            type: Boolean,
            default: false,
        },
        sizeSearch: {
            type: Number,
            default: 12,
        },
        sortBy: {
            type: String,
            default: "",
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            filterTerm: "",
            pageLength: 10,
        };
    },
    computed: {
        rowsTable() {
            if (this.filterTerm) {
                let rowsFiltered = [];
                rowsFiltered = this.rows.filter((o) =>
                    Object.keys(o).some((k) =>
                        String(o[k])
                            .toLowerCase()
                            .includes(this.filterTerm.toLowerCase())
                    )
                );
                return rowsFiltered;
            } else {
                return this.rows;
            }
        },
    },
};
</script>

<style lang="scss">

@media (max-width:480px) {
    .pagination-table{
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }
}

.b-table-sticky-column,
.table-b-table-default {
    background-color: #ffff !important;
    border: 1px solid #ebe9f1 !important;
    .dark-layout & {
        background-color: #283046 !important;
        color: #d0d2d6 !important;
    }
}
#table-default {
    &::-webkit-scrollbar {
    height: 5px;               /* width of the entire scrollbar */
    border-radius: 50%;
    }
    
    &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #FBEEE8;        /* color of the tracking area */
    margin: 0 16px;
    }
    
    &::-webkit-scrollbar-thumb {
    background-color: #CFC4BE;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    }

    thead th {
        width: 1%;
        text-transform: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #4c4541;
        outline: none;
        border-top: 1px solid #ebe9f1;
        background-color: #faf6f2 !important;
        border-bottom: 0;
        .dark-layout & {
            background-color: #283046 !important;
            outline-color: #3b4253 !important;
        }
        &.col-name-type{
            width: 40%;
        }
    }
    tbody tr {
        td {
            width: 1%;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4c4541;
        }

        .custom-switch {
            align-items: center;
            .custom-control-input:checked ~ .custom-control-label::before {
                background-color: #974900 !important;
            }
            .custom-control-input:checked ~ .custom-control-label::after {
                left: -7px !important;
            }
            .custom-control-label::before {
                width: 22px;
                height: 12px;
            }
            .custom-control-label::after {
                top: 1.6px;
                left: 2px;
                width: 8px;
                height: 8px;
            }
        }
    }
    tbody {
        tr:nth-child(2n + 1) {
            background-color: #ffff;
        }
        tr:nth-child(2n) {
            background-color: #fafafa;
        }
    }
}
.b-table-sticky-column.fix-1 {
    padding: 9px 52px !important;
}
.b-table-sticky-column.fix-2 {
    left: 187px !important;
    padding: 9px 20px !important;
}
.b-table-sticky-column.fix-3 {
    left: 301px !important;
    padding: 9px 30px !important;
}
.pagination {
    .page-item.active {
        height: 33px;
        button {
            background-color: #974900;
        }
    }
    .page-item.disabled {
        .page-link {
            background-color: #faf6f2;
            color: #4c4541;
        }
    }
}

.card-body {
    padding: 16px;
    .card-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #4c4541;
        margin-bottom: 16px !important;
    }
    .input-search {
        margin-bottom: 8px;
    }
    .col-footer-table {
        .por-pagina {
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4c4541;
            }
        }
        .custom-select {
            padding: 4px 34px 4px 8px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23974900' stroke='%23974900' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        }
    }
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 1px solid #CFC4BE;
    margin: 16px;
    border-radius: 5px;
    width: 100%;
    span {
        display: flex;
        align-items: baseline;
        h3 {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #998F8A;
        }
        svg {
            margin-right: 16.13px;
        }
    }
}
</style>
