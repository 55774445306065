<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
    >
      <div class="breadcrumbs-top">
        <div>
          <div class="breadcrumb-wrapper row" :style="Object.keys($slots).length > 0 ? '' : 'margin-bottom:0px;'">
            <b-breadcrumb class="breadcrumb-slash">
              <b-breadcrumb-item :active="true">
                <IconIntegration v-if="breadcrumb[0].icon == 'integration'"/>
                <IconSettings v-else-if="breadcrumb[0].icon == 'settings'"/>
                <IconOperationProgress v-else-if="breadcrumb[0].icon == 'operation'"/>
                <IconOrder v-else-if="breadcrumb[0].icon == 'order'"/>
                <IconDashboard v-else-if="breadcrumb[0].icon == 'dashboard'"/>
                <IconProfile v-else-if="breadcrumb[0].icon == 'profile'"/>
                <IconOperator v-else-if="breadcrumb[0].icon == 'operator'"/>
                <IconHome v-else/>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumb"
                :key="item.text"
                :active="item.to ? false : true"
                :to="item.to"
                :class="[
                item.to && 'navigable',
                item.active && 'activePage'
                ]"

              >
                {{
                  item.active && objectType ? getObjectType()
                  : item.active && pipelineName != '' ? pipelineName
                  : $t(item.text)
                }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>

        <div class="slot-breadcrumb" v-if="Object.keys($slots).length > 0 ? true : false">
          <slot name="col-right" />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import IconHome from '../../assets/images/pages/home-page-icon.svg'
import IconIntegration from '../../assets/images/pages/integration-page-icon.svg'
import IconSettings from '../../assets/images/pages/settings-page-icon.svg'
import IconOperationProgress from '../../assets/images/pages/operation-progress-page-icon.svg'
import IconOrder from '../../assets/images/pages/order-page-icon.svg'
import IconDashboard from '../../assets/images/pages/dashboard-page-icon.svg'
import IconProfile from '../../assets/images/pages/profile-icon.svg'
import IconOperator from '@/assets/images/pages/operator-mode-icon.svg'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    IconHome,
    IconIntegration,
    IconSettings,
    IconOperationProgress,
    IconOrder,
    IconDashboard,
    IconProfile,
    IconOperator
  },
  props:{
    objectType:{
      type: String
    },
    pipelineName: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      breadcrumb: {},
    }
  },
  beforeMount() {
    this.breadcrumb = this.$route.meta.breadcrumb
  },
  methods: {
    getObjectType() {
      switch (this.objectType){
        case '2':
          return 'SQL Server';
        case '3':
          return 'SAP';
        case '4':
          return 'CSV';
        case '5':
          return 'REST';
        case '6':
          return 'Data Factory'
        default:
          return 'TROIKA SFC';
      }
    }
  },
  watch:{
     '$route.meta.breadcrumb'(){
       this.breadcrumb = this.$route.meta.breadcrumb
     }
  }
}
</script>
<style lang="scss">

@media (max-width: 650px) {
  .breadcrumbs-top {
    flex-direction: column;
    .breadcrumb-wrapper{
      margin-bottom: 24px;
    }
  }
}

.breadcrumbs-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .breadcrumb-wrapper{
      .breadcrumb-item{
          display: flex;
          align-items: center;
          &::before{
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
          }
          span{
              display: flex;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #998F8A;

          }
          &.activePage{
              span{
                  color: #974900;
              }
          }
          &.navigable{
              a{
                  color: #998F8A;
              }
          }
      }
  }

  &.slot-breadcrumb {
    flex: 1;
  }
}
</style>
